<script>




    let dbg = false;

    const DEVMODE = import.meta.env.DEV;
    if (!DEVMODE) dbg=false;

    const _key = 'HargaPurataCard';
    $: dbg&&console.group('%c'+_key,'color:magenta')

    import * as d3 from 'd3';
    const f2 = d3.format('.2f');
    import chroma from 'chroma-js';



    export let d;
    export let size;
    // export let metric;
    // export let _scale;
    export let _scale1;
    export let _scale2;
    export let _color;





    import {
        urlBarang
    } from "$lib/components/Utilities.svelte";



    import {
        ItemCard,
        // PremiseCardWhite,
        // PremiseHargaBarang
    } from "$lib/components/Cards/";


    import * as Card from "$lib/components/ui/card";


    $: dbg&&console.groupEnd(_key);


</script>


{#if d.lookup_item}

    <Card.Root class="
        group
        flex-1
        min-w-[140px] max-w-[150px]
        rounded-2xl
        shadow

        bg-[#f7f7f7]
        hover:bg-[#427FED]
        text-black
        hover:text-white

        pb-2
    "
    >
        <a
            href="{
                d.url
                    ?   d.url
                    :   urlBarang(
                            d.lookup_item.item, d.lookup_item.item_code,
                        )
            }"
        >


            <!--
                price diff
            -->
            {#if +f2(d['prev_diff'])==0 || !d['prev_diff']}
              <div class="min-h-[60px] text-center p-2  rounded-t-2xl shadow-lg"
                style="
                    background-color: { _color(_scale1(0)) };
                    color:'black';
                ">
                  <div class="text-xs m-0 p-0">Harga purata kekal</div>
              </div>
            {:else if d['prev_diff']<0}

              <div class="min-h-[60px] text-center p-2  rounded-t-2xl shadow-lg"
                style="
                    background-color: { _color(_scale1(d['prev_diff'])) };
                    color: { chroma(_color(_scale1(d['prev_diff']))).luminance() > 0.4 ? 'black' : 'white' };
                ">
                  <div class="text-xs m-0 p-0">Harga purata turun</div>
                  <span class="">-RM{ f2(Math.abs(d['prev_diff'])) }</span>
              </div>

              <!-- <div class="min-h-[60px] text-center p-2  text-white bg-[#427FED] rounded-t-2xl">
                  <div class="text-xs m-0 p-0">Harga purata turun</div>
                  <span class="">-RM{ f2(Math.abs(d['prev_diff'])) }</span>
              </div> -->

            {:else if d['prev_diff']>0}

              <div class="min-h-[60px] text-center p-2 rounded-t-2xl shadow-lg"
                style="
                    background-color: { _color(_scale2(d['prev_diff'])) };
                    color: { chroma(_color(_scale2(d['prev_diff']))).luminance() > 0.4 ? 'black' : 'white' };
                ">
                  <div class="text-xs m-0 p-0">Harga purata naik</div>
                  <span class="">+RM{ f2(Math.abs(d['prev_diff'])) }</span>
              </div>

            {/if}


            <!--
                harga purata
            -->

            <div class="
                text-center
                p-2"
            >
              <span class="item_price tracking-normal  text-xl font-bold text-[#427FED] group-hover:text-[white]"
              >RM{ f2(d['avg']) }</span><span class="text-xs whitespace-nowrap text-slate-500 group-hover:text-[white]">/{@html d.lookup_item.unit}</span>

            </div>




            <!--
                item
            -->
            <div class="
                rounded-tl-3xl
                group-hover:bg-opacity-60
                overflow-hidden
            "
            >
                <ItemCard {d} {size}/>
            </div>




        </a>

    </Card.Root>

{/if}


